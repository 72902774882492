<template>
<div class="users-user text-center m-0 m-md-2 py-2 px-4" :id="'users_user_row_' + user.id" >
    <div>
        <b-img class="users-user-image" :src="avatar" />
    </div>
    <div>
        {{user.username}}
    </div>
    <b-badge :variant="access.bg">{{access.text}}</b-badge>
    <UserPopover :target="'users_user_row_' + user.id" :user="{ userid: user.id, username: user.username}"></UserPopover>
</div>
</template>

<script>
import tournamentsenum from "@/helpers/enums/tournaments";

export default {
    name: "GroupTeam",
    props: ["user"],
    components: {},
    data() {
        return {};
    },
    computed: {
        avatar() {
            return this.$images.userAvatar(this.user.id, this.user.imgversion);
        },
        access() {
            return tournamentsenum.getUserAccess(this.user.access);
        }
    },
    methods: {       
    }
};
</script>
